.clear {
  clear: both;
}

.clear-after:after {
  clear: both;
  display: block;
  content: "";
  height: 0;
}

.error {
  color: var(--red);
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/* Displays */
.display-block {
  display: block;
}

.display-inlineblock {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.display-none {
  display: none;
}

/* Flex box definitions */
.flex,
.flexbox {
  display: flex !important;
}

.inline-flex {
  display: inline-flex;
}

.hcenter,
.justify-content-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.hend,
.justify-content-end {
  justify-content: end;
}

.vcenter,
.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: start;
}

.vtop {
  align-items: baseline;
}

.vbottom,
.flex-align-end {
  align-items: flex-end;
}

.align-self-top {
  align-self: flex-start;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-row-important {
  flex-direction: row !important;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

/* Text definitions */
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.break-spaces {
  white-space: break-spaces !important;
}

.ellipsis {
  text-overflow: ellipsis;
}

.allCaps {
  text-transform: capitalize;
}

.strike-through {
  text-decoration-line: line-through;
  text-decoration-thickness: 2.5px;
}

.underline {
  text-decoration: underline;
}

/* Heights */
.height-double {
  height: calc(var(--size) * 2) !important;
}

.height-triple {
  height: calc(var(--size) * 3);
}

.default-height,
.height-formitem {
  height: calc(var(--size) * 4);
}

.line-height-fix {
  line-height: 1;
}
.line-height-double {
  line-height: calc(var(--size) * 2);
}

.line-height-triple {
  line-height: calc(var(--size) * 3);
}

.line-height-formitem {
  line-height: calc(var(--size) * 4);
}

.min-height-150 {
  min-height: 150px !important;
}

/* Widths */
.width40 {
  width: 40px !important;
}

.width-70 {
  width: 70px !important;
}
.width-150 {
  width: 150px !important;
}

.width10p {
  width: 10% !important;
}

.width20p {
  width: 20% !important;
}

.width30p {
  width: 30% !important;
}

.width40p {
  width: 40% !important;
}

.width50p {
  width: 50% !important;
}

.width-100p {
  width: 100% !important;
}

.width-fit-content {
  width: fit-content;
}

.min-width-40 {
  min-width: 40px !important;
}

.min-width-70 {
  min-width: 70px !important;
}

.min-width-100 {
  min-width: 100px !important;
}

.max-width-250 {
  max-width: 250px !important;
}

.max-width-600 {
  max-width: 600px !important;
}

.max-width-100p {
  max-width: 100% !important;
}

/* Min Width */
.min-width-auto {
  min-width: auto !important;
}

/* Cursors */
.cursor_default {
  cursor: default !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.cursor_not-allowed {
  cursor: not-allowed !important;
}

/* Overflows */
.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-auto {
  overflow: auto !important;
}

/* Underline */
.underline-dashed {
  border-bottom: 1px dashed var(--grey-99) !important;
}

.underline-dashed--light {
  border-bottom: 1px dashed var(--grey-ee) !important;
}

.backface-hidden {
  backface-visibility: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  border: 0;
}

.grid {
  display: grid;
}
.grid-gap-5 {
  gap: 5px;
}
.grid-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
.-translateY-2_5 {
  transform: translateY(-2.5px);
}
