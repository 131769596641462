@import "~react-toastify/dist/ReactToastify.min.css";

.toast-round .Toastify__toast {
  border-radius: var(--size);
  padding: var(--padding);
  text-align: center;
}
.toast-round .Toastify__toast .Toastify__toast-body {
  display: block;
  padding: 0;
}
.toast-round .Toastify__toast .Toastify__close-button {
  color: var(--white);
}
.toast-round .Toastify__toast .Toastify__toast-icon * {
  display: none;
}
.toast-round .Toastify__toast.Toastify__toast--success {
  background: var(--green-dark);
  color: var(--white);
}
.toast-round .Toastify__toast.Toastify__toast--error {
  background: var(--red-dark);
  color: var(--white);
}
.toast-round .Toastify__toast.Toastify__toast--info {
  background: var(--blue);
  color: var(--white);
}
